<template>
  <div>
    <portal to="report-subheader">
      <div class="subheader">
        <a :href="websiteUrl" target="_blank">
          {{ websiteUrl }}
        </a>
      </div>
    </portal>
    <CRow>
      <CCol col="9">
        <Widgets :data="data" />
        <CRow>
          <CCol col="4">
            <VisitorsByLocation :data="data" />
          </CCol>
          <CCol col="8">
            <VisitsPerHourChart :data="data" />
          </CCol>
        </CRow>
      </CCol>
      <CCol col="3">
        <DeviceUsageCharts :data="data" />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { eachDayOfInterval, format, formatISO } from 'date-fns'

import { ReportingAPI } from '@/services/api/resources'

import DeviceUsageCharts from './DeviceUsageCharts'
import VisitorsByLocation from './VisitorsByLocation'
import VisitsPerHourChart from './VisitsPerHourChart'
import Widgets from './Widgets'

export default {
  name: 'RedirectLinksReport',
  components: {
    Widgets,
    VisitsPerHourChart,
    VisitorsByLocation,
    DeviceUsageCharts
  },
  props: {
    company: {
      type: Object,
      required: true
    },
    property: {
      type: Object,
      required: true
    },
    dateRange: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      data: {
        browsers: {},
        devices: {},
        locations: {},
        operating_systems: {},
        visits: {},
        total_visits: 0,
        most_active_time_of_day: 0
      }
    }
  },
  computed: {
    websiteUrl () {
      return this.property.property.short_url_target || this.property.property.website_url
    }
  },
  watch: {
    dateRange () {
      this.fetchRedirectLinksReport()
    }
  },
  async created () {
    await this.fetchRedirectLinksReport()

    if (this.data.total_visits === 0) {
      this.$emit('no-data')
    }
  },
  methods: {
    async fetchRedirectLinksReport () {
      this.$emit('loading', true)
      const params = {
        from_date: formatISO(this.dateRange.start),
        to_date: formatISO(this.dateRange.end),
        tz: this.dateRange.tz
      }
      const response = await ReportingAPI.property.redirect_links({
        id: this.$route.params.pid,
        params
      })
      if (response) {
        const data = response.data

        // Rename long browser names to fit within constrained widget space
        const browserMap = {
          'Samsung Internet': 'Samsung',
          'Internet Explorer': 'IE',
          'Slackbot-LinkExpanding': 'Slackbot'
        }
        for (const [longName, shortName] of Object.entries(browserMap)) {
          if (data.browsers.hasOwnProperty(longName)) {
            data.browsers[shortName] = data.browsers[longName]
            delete data.browsers[longName]
          }
        }
        this.data = data
      }
      this.$emit('loading', false)
    },
    /**
     * onRefresh handler called by BaseReport component.
     */
    refresh () {
      this.fetchRedirectLinksReport()
    },
    /**
     * onExport handler called by BaseReport component.
     *
     * Returns the number of visits broken down by day,
     * the other data can't easily be coerced
     * into a structure that fits our supported export formats.
     *
     * @returns {Array}
     */
    exportData () {
      const data = eachDayOfInterval({
        start: this.dateRange.start,
        end: this.dateRange.end
      }).map(date => {
        return {
          date: format(date, 'yyyy-MM-dd'),
          visits: 0
        }
      })
      for (const [day, hourlyData] of Object.entries(this.data.visits)) {
        const row = data.find(el => el.date === day)
        const visitsPerDay = Object.values(hourlyData).reduce((total, count) => total + count, 0)
        row.visits = visitsPerDay
      }
      return data
    }
  }
}
</script>

<style lang="scss" scoped>
.subheader a {
  color: $info;
  font-weight: 600;
  font-size: 1.1rem;
}
</style>
