<template>
  <ReportingCard
    title="Visitors by Location"
    info-text="This map shows the locations of visitors to your website. This map is updated every 3 hours and only displays users located in the USA."
  >
    <CRow>
      <CCol>
        <GChart
          id="geochart"
          type="GeoChart"
          :data="chartData"
          :settings="settings"
          :options="options"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <div class="city-list">
          <div v-for="(location, index) in locationData" :key="index" class="pb-2">
            <CRow align-vertical="center" align-horizontal="between">
              <CCol>
                <CRow>
                  <CCol class="city">
                    {{ location.city }}
                  </CCol>
                </CRow>
                <CRow>
                  <CCol class="location">
                    {{ `${location.state}, ${location.country}` }}
                  </CCol>
                </CRow>
              </CCol>
              <CCol class="visits mr-3">
                {{ location.visits }} Visitors
              </CCol>
            </CRow>
          </div>
        </div>
      </CCol>
    </CRow>
  </ReportingCard>
</template>

<script>
import { GChart } from 'vue-google-charts'

import { isEmptyObject } from '@/utils'
import { ReportingCard } from '@/views/reporting/_components'

export default {
  name: 'VisitorsByLocation',
  components: {
    ReportingCard,
    GChart
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      settings: {
        packages: [ 'geochart' ],
        mapsApiKey: 'AIzaSyCipLQv8rEgvo8ukAprEU-c1tPWignv6LU'
      },
      options: {
        displayMode: 'regions',
        region: 'US',
        resolution: 'provinces',
        datalessRegionColor: '#d7e8f7',
        legend: 'none',
        backgroundColor: {
          strokeWidth: 0
        },
        colorAxis: {
          colors: [ '#a7d0f2', '#2F75B3', '#1a62a3' ]
        }
      }
    }
  },
  computed: {
    /**
     * Split location data from the api into individual components.
     *
     * Example location object:
     *
     * {
     *   cities: {
     *     'Aurora, Illinois, United States': 3
     *   }
     * }
     *
     * Result:
     *
     * [
     *   { city: 'Aurora', state: 'Illinois', country: 'United States', visits: 3 }
     * ]
     *
     * @returns {Object}
     */
    locationData () {
      if (!this.data.locations || isEmptyObject(this.data.locations)) {
        return []
      }
      const cities = Object.entries(this.data.locations.cities)
      if (!cities.length) {
        return []
      }
      const locationData = []

      for (const [location, visits] of cities) {
        let [ city, state, country ] = location.split(', ')
        if (country === 'United States') {
          country = 'USA'
        }
        locationData.push({ city, state, country, visits })
      }
      return locationData.sort((a, b) => b.visits - a.visits)
    },
    chartData () {
      const headers = ['State', 'Visits']
      const data = {}

      for (const location of this.locationData) {
        const state = `${location.state}, ${location.country}`
        if (data.hasOwnProperty(state)) {
          data[state] += location.visits
        } else {
          data[state] = location.visits
        }
      }

      // GeoChart takes an array of arrays, where the first item is used as the headers
      return [headers, ...Object.entries(data)]
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep #geochart {
  padding-left: 12px;
  path {
    stroke: #fff;
  }
}
::v-deep .card-body {
  padding: 0 0 0 10px;
}
.city-list {
  max-height: 260px;
  overflow-y: auto;
  overflow-x: hidden;
  .city {
    color: $info;
    font-size: 0.9rem;
    font-weight: bold;
  }
  .location {
    font-size: 0.8rem;
    font-weight: 600;
  }
  .visits {
    text-align: right;
    font-weight: bold;
  }
}
</style>
