<template>
  <ReportingCard
    title="Visits Per Hour"
    info-text="An average of the most popular visit times by day of the week during the selected time span."
  >
    <HeatmapChart :series="series" :options="options" />
  </ReportingCard>
</template>

<script>
import { HeatmapChart, ReportingCard } from '@/views/reporting/_components'
import { heatmapMixin } from '@/views/reporting/_mixins'

export default {
  name: 'VisitsPerHourChart',
  components: {
    HeatmapChart,
    ReportingCard
  },
  mixins: [
    heatmapMixin
  ],
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      options: {
        chart: {
          height: '430'
        }
      }
    }
  },
  computed: {
    series () {
      return this.$_heatmapMixin_generateSeries([
        this.data.visits
      ])
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .card-body {
  padding: 0;
}
</style>
