<template>
  <div>
    <ReportingCard title="Device Usage">
      <RChartDonut :data="data.devices" :options="options" />
    </ReportingCard>
    <ReportingCard title="Browser Usage">
      <RChartDonut :data="data.browsers" :options="options" />
    </ReportingCard>
    <ReportingCard title="Operating System">
      <RChartDonut :data="data.operating_systems" :options="options" />
    </ReportingCard>
  </div>
</template>

<script>
import { RChartDonut } from '@/components/charts'
import { ReportingCard } from '@/views/reporting/_components'

export default {
  name: 'DeviceUsageCharts',
  components: {
    ReportingCard,
    RChartDonut
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      options: {
        chart: {
          height: 100
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .card-body {
  padding: 0.5rem;
}
</style>
