<template>
  <CRow class="widgets">
    <CCol>
      <ReportingWidget
        title="Top Visitor Location"
        :text="widgetData.topLocation"
        icon="md-place"
        info-text="The most common location of visitors searching your website."
      />
    </CCol>
    <CCol>
      <ReportingWidget
        title="Website Visitors"
        :text="widgetData.visits"
        icon="md-chart"
        info-text="Total number of unique visitors to your website during the selected time span."
      />
    </CCol>
    <CCol>
      <ReportingWidget
        title="Most Active Time"
        :text="widgetData.mostActiveTime"
        icon="md-schedule"
        info-text="Time when your website is most active."
      />
    </CCol>
  </CRow>
</template>

<script>
import { isEmptyObject } from '@/utils'
import constants from '@/utils/constants'
import { ReportingWidget } from '@/views/reporting/_components'

export default {
  name: 'Widgets',
  components: {
    ReportingWidget
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    widgetData () {
      return {
        mostActiveTime: this.formatHour(this.data.most_active_time_of_day),
        visits: this.data.total_visits > 0 ? `${this.data.total_visits} Visitors` : null,
        topLocation: this.getTopLocation()
      }
    }
  },
  created () {
    this.stateMap = constants.address.State.getAsObject({ inverted: true })
  },
  methods: {
    /**
     * The API gives location data in the following format:
     *
     * {
     *   cities: {
     *     'City, State, Country': Count
     *   },
     *   lat_long: {
     *     'Latitude, Longitude': Count
     *   }
     * }
     *
     * This parses out the city and state values, sorts them by
     * the highest count, then returns that value.
     *
     * @returns {String|null}
     */
    getTopLocation () {
      if (!this.data.locations || isEmptyObject(this.data.locations)) {
        return null
      }
      const cities = Object.entries(this.data.locations.cities)
      if (!cities.length) {
        return null
      }
      const topLocation = cities.sort((a, b) => b[1] - a[1])[0]
      const [city, state] = topLocation[0].split(', ')
      const stateAbbrev = this.stateMap[state]
      return `${city}, ${stateAbbrev || state}`
    },
    /**
     * Formats 24 hour into 12 hour string representation.
     * e.g. '00' = '12 AM', '16' = '4 PM'
     *
     * @param {String} hour - 24 hour string
     * @returns {String}
     */
    formatHour (hour) {
      if (!hour) return null
      const hour12 = hour % 12 || 12
      const period = hour < 12 ? 'AM' : 'PM'
      return `${hour12} ${period}`
    }
  }
}
</script>
